@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Anek+Telugu:wght@300;400;500;600;700&family=Baloo+Paaji+2:wght@700&family=Bitter:ital,wght@0,300;0,500;1,400&family=Cormorant:ital,wght@1,300&family=Fasthand&family=Lato:ital,wght@0,300;0,400;0,700;1,700&family=Mukta:wght@200;400&family=Oswald:wght@200&family=Poppins:wght@400;700&family=Quicksand:wght@400;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500&family=Satisfy&family=Titan+One&family=Varela+Round&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  button{
    @apply border border-black rounded-xl px-5 py-1 
  }
}